import CloseIcon from '@mui/icons-material/Close';
import {
  Container,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  LinearProgress,
  MenuItem,
  Pagination,
  Select,
  styled,
} from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  useArrivalDestinations,
  useCharterFlights,
  useDepartureDestinations,
} from './api/charterFlights';
import FlightCard from './components/FlightCard/FlightCard';
import Header from './components/Header/Header';
import { FlightType } from './types/flight.type';

const StyledSelect = styled(Select)({
  backgroundColor: '#fcfffc',
  color: '#004d40',
  border: '1px solid #388e3c3d',
  borderRadius: '4px',
});

const MissingFlightsContainer = styled('div')({
  fontSize: '2rem',
  textAalign: 'center',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
});

const StyledLinearProgress = styled(LinearProgress)({
  width: '100%',
});

const itemsPerPage = 20;

const getAvilableDestinationsTags = (flights?: FlightType[]) => {
  const initialValue = {
    arrivalsTags: {} as any,
    departureTags: {} as any,
  };
  return flights
    ? flights.reduce((prev, current) => {
        if (
          prev.arrivalsTags[current.startDepartureFromAirportTag]?.[
            current.startArrivalToAirportTag
          ]
        ) {
          prev.arrivalsTags[current.startDepartureFromAirportTag][
            current.startArrivalToAirportTag
          ]++;
        } else {
          prev.arrivalsTags[current.startDepartureFromAirportTag] = {};
          prev.arrivalsTags[current.startDepartureFromAirportTag][
            current.startArrivalToAirportTag
          ] = 1;
        }

        if (
          prev.departureTags[current.returnDepartureFromAirportTag]?.[
            current.returnArrivalToAirportTag
          ]
        ) {
          prev.departureTags[current.returnDepartureFromAirportTag][
            current.returnArrivalToAirportTag
          ]++;
        } else {
          prev.departureTags[current.returnDepartureFromAirportTag] = {};
          prev.departureTags[current.returnDepartureFromAirportTag][
            current.returnArrivalToAirportTag
          ] = 1;
        }

        return prev;
      }, initialValue)
    : initialValue;
};

export default function Index() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [departure, setDeparture] = useState<string>(
    searchParams.get('departures') || ''
  );
  const [destination, setDestination] = useState<string>(
    searchParams.get('destination') || ''
  );
  const [page, setPage] = useState(1);

  const { data, isLoading } = useCharterFlights(departure, destination);
  const { data: arrivalData, isLoading: isArrivalLoading } =
    useArrivalDestinations();
  const { data: departureData, isLoading: isDepartureLoading } =
    useDepartureDestinations();

  const flightsCounter = useMemo(
    () =>
      departure || destination
        ? getAvilableDestinationsTags(data?.items)
        : undefined,
    [data?.items, departure, destination]
  );

  const isGlobalLoading = isLoading || isArrivalLoading || isDepartureLoading;

  const handleDepartureChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const params = new URL(document.location.toString()).searchParams;
    value === ''
      ? params.delete('departures')
      : params.set('departures', value);
    params.delete('page');
    setSearchParams(params, {
      replace: false,
      preventScrollReset: true,
    });

    setDeparture(value);
    setPage(0);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleDestinationChange = (event: any) => {
    const {
      target: { value },
    } = event;

    const params = new URL(document.location.toString()).searchParams;
    value === ''
      ? params.delete('destination')
      : params.set('destination', value);
    params.delete('page');

    setSearchParams(params, {
      replace: false,
      preventScrollReset: true,
    });

    setDestination(value);
    setPage(0);

    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const totalPages = useMemo(
    () => Math.ceil((data?.items || []).length / itemsPerPage),
    [data?.items]
  );

  useEffect(() => {
    const pageParam = parseInt(searchParams.get('page') ?? '1');

    setPage(pageParam);
  }, [searchParams]);

  const handleChangePage = (_: any, page: number) => {
    const params = new URL(document.location.toString()).searchParams;
    params.set('page', page.toString());
    setSearchParams(params, {
      preventScrollReset: true,
    });
    setPage(page);
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const paginatedFlights = useMemo(
    () =>
      data?.items.length
        ? data?.items.slice((page - 1) * itemsPerPage, page * itemsPerPage)
        : [],
    [data, page]
  );

  if (isGlobalLoading) {
    return <StyledLinearProgress color="success" />;
  }

  return (
    <div>
      <Header />
      <Container style={{ marginTop: '2rem' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="departure-label">Miejsce odlotu</InputLabel>
              <StyledSelect
                labelId="departure-label"
                id="departure"
                value={departure}
                label="Miejsce odlotu"
                onChange={handleDepartureChange}
                MenuProps={{
                  disableScrollLock: true,
                  style: {
                    maxHeight: 400,
                  },
                }}
                endAdornment={
                  departure && (
                    <InputAdornment sx={{ marginRight: '10px' }} position="end">
                      <IconButton
                        onClick={() => {
                          handleDepartureChange({ target: { value: '' } });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {departureData?.items.map((item, key) => {
                  const additionalValue =
                    flightsCounter && destination
                      ? flightsCounter?.arrivalsTags?.[item.airplaneTag]?.[
                          destination
                        ] || '0'
                      : '';

                  return (
                    <MenuItem value={item.airplaneTag} key={key}>
                      {item.country} - {item.city} {additionalValue}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="destination-label">Miejsce przylotu</InputLabel>
              <StyledSelect
                labelId="destination-label"
                id="destination"
                value={destination}
                label="Miejsce przylotu"
                onChange={handleDestinationChange}
                MenuProps={{
                  disableScrollLock: true,
                  style: {
                    maxHeight: 400,
                  },
                }}
                endAdornment={
                  destination && (
                    <InputAdornment sx={{ marginRight: '10px' }} position="end">
                      <IconButton
                        onClick={() => {
                          handleDestinationChange({ target: { value: '' } });
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </InputAdornment>
                  )
                }
              >
                {arrivalData?.items.map((item, key) => {
                  const additionalValue =
                    flightsCounter && departure
                      ? flightsCounter?.departureTags?.[item.airplaneTag]?.[
                          departure
                        ] || '0'
                      : '';

                  return (
                    <MenuItem value={item.airplaneTag} key={key}>
                      {item.country} - {item.city} {additionalValue}
                    </MenuItem>
                  );
                })}
              </StyledSelect>
            </FormControl>
          </Grid>
        </Grid>

        <Grid
          container
          spacing={3}
          style={{ marginTop: '1rem', marginBottom: '1rem' }}
        >
          <Grid item xs={12}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </Grid>
          {!!paginatedFlights?.length &&
            paginatedFlights.map((flight, index) => (
              <FlightCard flight={flight} key={index} />
            ))}
          {!paginatedFlights?.length && (
            <MissingFlightsContainer>
              Przepraszamy, brak lotów dla podanych parametrów
            </MissingFlightsContainer>
          )}
          <Grid item xs={12}>
            <Pagination
              count={totalPages}
              page={page}
              onChange={handleChangePage}
              style={{
                marginBottom: '1rem',
                display: 'flex',
                justifyContent: 'center',
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
