import { useQuery } from '@tanstack/react-query';
import { Destination } from './../types/destination.type';
import { FlightType } from './../types/flight.type';

const API_LINK = 'https://api.lotyloty.pl';

const getCharterFlights = async (departure: string, destination: string) => {
  const url = new URL(`${API_LINK}/charter-flights`);


  if (departure) {
    url.searchParams.set('departure-airport-tag', departure);
  }
  if (destination) {
    url.searchParams.set('arrival-airport-tag', destination);
  }

  const res = await fetch(url);

  return res.json();
};

export const useCharterFlights = (departure: string, destination: string) => 
{
  return useQuery<{items: FlightType[]}>({
    queryKey: ['charter-flighs', departure, destination],
    queryFn: () => getCharterFlights(departure, destination),
    retry: false,
    retryOnMount: false,
  });
};

const getDeparturesDestinations = async () => {
  const res = await fetch(`${API_LINK}/airports-departure`);

  return res.json();
};

export const useDepartureDestinations = () => {
  return useQuery<{items: Destination[]}>({
    queryKey: ['departures'],
    queryFn: getDeparturesDestinations,
    retry: false,
    retryOnMount: false,
  });
};

const getArrivallDestinations = async () => {
  const res = await fetch(`${API_LINK}/airports-arrival`);

  return res.json();
};

export const useArrivalDestinations = () => {
  return useQuery<{items: Destination[]}>({
    queryKey: ['arrivals'],
    queryFn: getArrivallDestinations,
    retry: false,
    retryOnMount: false,
  });
};
