import { Box, Card, CardContent, Grid, Typography } from '@mui/material';
import { styled } from '@mui/system';
import { FlightType } from './../../types/flight.type';

const CenteredGrid = styled(Grid)({
  display: 'inline-flex',
  justifyContent: 'center',
  alignItems: 'center',
});

const StrikedBox = styled(Box)({
  textDecoration: 'line-through',
});

const Logo = styled('img')({
  '@media (max-width: 600px)': {
    height: '25px',
  },
  height: '40px',
});

const Container = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
});

const FlightContainer = styled('div')({
  '@media (max-width: 600px)': {
    minWidth: '100px',
  },
  minWidth: '200px',
  margin: '12px',
});

const Title = styled('p')({
  margin: '0',
  fontSize: '24px',
  fontWeight: '600',
});

const Date = styled('p')({
  margin: '0',
  fontSize: '16px',
  fontWeight: '600',
});
// Styled Card component with hover effect
const HoverCard = styled(Card)(() => ({
  transition: 'transform 0.3s ease-in-out',
  cursor: 'pointer',
  '&:hover': {
    transform: 'scale(1.05)', // Slightly enlarge the card on hover
  },
}));

export default function FlightCard({ flight }: { flight: FlightType }) {
  return (
    <Grid
      item
      xs={12}
      key={flight.id}
      onClick={() => window.open(flight.link, '_blank')}
    >
      <HoverCard>
        <CardContent>
          <Box display="flex" justifyContent="space-between" textAlign="center">
            <StrikedBox bgcolor="lightblue" p={1} flexGrow={1}>
              <Typography variant="body1">
                <b>
                  Cena początkowa: <s>{flight.highestPrice}zł</s>
                </b>
              </Typography>
            </StrikedBox>
            <Box bgcolor="lightgreen" p={1} flexGrow={1}>
              <Typography variant="body1">
                <b>Cena aktualna: {flight.price}zł</b>
              </Typography>
            </Box>
            <Box bgcolor="lightcoral" p={1} flexGrow={1}>
              <Typography variant="body1">
                <b>Zniżka: {flight.promotionPercentage}%</b>
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardContent>
          <Grid
            style={{
              backgroundImage: 'url("click_to_open.png")',
              backgroundRepeat: 'no-repeat',
              backgroundPosition: 'center',
            }}
            container
          >
            <CenteredGrid item md={2} xs={3}>
              <Logo src="rainbow_logo.png" alt="company-logo" />
            </CenteredGrid>
            <Grid item md={10} xs={9}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <Container>
                    <FlightContainer>
                      <Title>{flight.startDepartureFromCountry}</Title>
                      {flight.startDepartureFromCity} (
                      {flight.startDepartureFromAirportTag})
                      <Date>
                        {flight.startDepartureDateAt.toLocaleString()}
                      </Date>
                    </FlightContainer>
                    <div id="css-line-arrow"></div>
                    <FlightContainer>
                      <Title>{flight.startArrivalToCountry}</Title>
                      {flight.startArrivalToCity} (
                      {flight.startArrivalToAirportTag})
                      <Date>{flight.startArrivalDateAt.toLocaleString()}</Date>
                    </FlightContainer>
                  </Container>
                </Grid>

                <Grid item xs={12}>
                  <Container>
                    <FlightContainer>
                      <Title>{flight.returnDepartureFromCountry}</Title>
                      {flight.returnDepartureFromCity} (
                      {flight.returnDepartureFromAirportTag})
                      <Date>
                        {flight.returnDepartureDateAt.toLocaleString()}
                      </Date>
                    </FlightContainer>
                    <div id="css-line-arrow"></div>
                    <FlightContainer>
                      <Title>{flight.returnArrivalToCountry}</Title>
                      {flight.returnArrivalToCity} (
                      {flight.returnArrivalToAirportTag})
                      <Date>{flight.returnArrivalDateAt.toLocaleString()}</Date>
                    </FlightContainer>
                  </Container>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </CardContent>
      </HoverCard>
    </Grid>
  );
}
