import {
  AppBar,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Toolbar,
  Typography,
  styled,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { forwardRef, useState } from 'react';

const StyledToolbar = styled(Toolbar)({
  background:
    'linear-gradient(90deg, rgb(150 160 123) 0%, rgb(255 255 255) 33%, rgb(60 125 78) 100%)',
  justifyContent: 'center',
  color: 'black',
});

const StyledLogo = styled('img')({
  width: '150px',
});
const StyledMainHeader = styled('h1')({
  fontSize: '1.3rem',
  color: 'black',
  margin: 0,
  padding: 4,
  background:
    'linear-gradient(90deg, rgb(150 160 123) 0%, rgb(255 255 255) 33%, rgb(60 125 78) 100%)',
  textAlign: 'center',
});

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function Header() {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <AppBar position="static">
      <Button variant="contained" onClick={handleClickOpen}>
        Kliknij aby zostawić dla nas feedback
      </Button>
      <StyledMainHeader>Oferty odświeżane są co godzinę!</StyledMainHeader>

      <StyledToolbar>
        <Typography variant="h6" component="div">
          <StyledLogo src="logo.png" alt="logo-loty" />
        </Typography>
      </StyledToolbar>

      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{'Podziel się swoją opinią!'}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Prosimy o wejście w ankietę google klikając{' '}
            <a href="https://forms.gle/Gqvephq4K8Nr3kXZ8" target="_blank">
              tutaj
            </a>
            . Będziemy wdzięczni za każdy komentarz, ponieważ dzięki nim możemy
            się rozwijać. Dzięki!
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </AppBar>
  );
}
